import { render, staticRenderFns } from "./StatistichePortali.vue?vue&type=template&id=ddd3c804&scoped=true&"
import script from "./StatistichePortali.vue?vue&type=script&lang=js&"
export * from "./StatistichePortali.vue?vue&type=script&lang=js&"
import style0 from "./StatistichePortali.vue?vue&type=style&index=0&id=ddd3c804&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddd3c804",
  null
  
)

export default component.exports